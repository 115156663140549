import { useEffect } from 'react';

const UnionBerlin = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 288);
    localStorage.setItem('club_name', '1. FC Union Berlin');

    window.location.assign('/');
  });

  return null;
};
export default UnionBerlin;
